exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-anfrage-gesendet-jsx": () => import("./../../../src/pages/anfrage-gesendet.jsx" /* webpackChunkName: "component---src-pages-anfrage-gesendet-jsx" */),
  "component---src-pages-datenschutz-jsx": () => import("./../../../src/pages/datenschutz.jsx" /* webpackChunkName: "component---src-pages-datenschutz-jsx" */),
  "component---src-pages-impressum-jsx": () => import("./../../../src/pages/impressum.jsx" /* webpackChunkName: "component---src-pages-impressum-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-kontakt-jsx": () => import("./../../../src/pages/kontakt.jsx" /* webpackChunkName: "component---src-pages-kontakt-jsx" */),
  "component---src-pages-privatpersonen-jsx": () => import("./../../../src/pages/privatpersonen.jsx" /* webpackChunkName: "component---src-pages-privatpersonen-jsx" */),
  "component---src-pages-unternehmen-jsx": () => import("./../../../src/pages/unternehmen.jsx" /* webpackChunkName: "component---src-pages-unternehmen-jsx" */)
}

